import api from '@/config/api';
import { getSession } from 'next-auth/react';
import axios, { AxiosError } from 'axios';
import { Hotel, HotelFormData } from '@/types/hotel';
import { getSocket } from '@/hooks/useSocket';
import { createOrGetOrganization } from '../organizations';

export const getHotels = async (): Promise<Hotel[]> => {
  try {
    const response = await api.get<Hotel[]>('/hotels');
    return response.data;
  } catch (error) {
    console.error('Error fetching hotels:', error);
    throw error;
  }
};

export const getHotelDetails = async (id: string): Promise<Hotel> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    const response = await api.get<Hotel>(`/hotels/${id}`, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createHotel = async (data: HotelFormData) => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    const { photos, rooms, ...hotelData } = data;

    // Process rooms and pricing periods
    const processedRooms = await Promise.all(rooms.map(async (room) => {
      const processedPeriods = await Promise.all(room.pricingPeriods.map(async (period) => {
        if (!period.organizationId) {
          const organizationName = data.name.en; // Using English name as the organization name
          const organization = await createOrGetOrganization(organizationName);
          period.organizationId = organization.id;
        }
        return period;
      }));
      return { ...room, pricingPeriods: processedPeriods };
    }));

    const response = await api.post('/hotels', {
      ...hotelData,
      translations: {
        en: { name: data.name.en, description: data.description.en },
        ar: { name: data.name.ar, description: data.description.ar },
        id: { name: data.name.id, description: data.description.id },
        tr: { name: data.name.tr, description: data.description.tr },
      },
      photos: photos.map(photo => ({ url: photo.url })),
      rooms: processedRooms,
    }, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // This is an Axios error
      const axiosError = error as AxiosError;
      if (axiosError.response) {
      }
    } else {
    }
    throw error;
  }
};

export const updateHotel = async ({ id, data }: { id: string; data: HotelFormData }) => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    const { photos, rooms, ...hotelData } = data;

    // Process rooms and pricing periods
    const processedRooms = await Promise.all(rooms.map(async (room) => {
      const processedPeriods = await Promise.all(room.pricingPeriods.map(async (period) => {
        if (!period.organizationId) {
          const organizationName = data.name.en; // Using English name as the organization name
          const organization = await createOrGetOrganization(organizationName);
          period.organizationId = organization.id;
        }
        return period;
      }));
      return { ...room, pricingPeriods: processedPeriods };
    }));

    const response = await api.patch(`/hotels/${id}`, {
      ...hotelData,
      amenities: hotelData.amenities.map(amenity => amenity.value),
      translations: [
        { language: 'en', name: data.name.en, description: data.description.en },
        { language: 'ar', name: data.name.ar, description: data.description.ar },
        { language: 'id', name: data.name.id, description: data.description.id },
        { language: 'tr', name: data.name.tr, description: data.description.tr },
      ],
      photos: photos.map(photo => ({ url: photo.url })),
      rooms: processedRooms,
    }, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating hotel:', error);
    throw error;
  }
};

export const scrapeHotelData = async (bookingLink: string): Promise<{ jobId: string }> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }

    const response = await api.post('/hotels/scrape', { url: bookingLink }, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error initiating hotel data scraping:', error);
    throw error;
  }
};

export const listenForScrapingResult = (
  jobId: string,
  onStart: () => void,
  onComplete: (result: any) => void,
  onError: (error: string) => void
) => {
  const socket = getSocket();
  let isCompleted = false;


  const setupListeners = () => {
    socket.on('scrapingStarted', startedListener);
    socket.on('scrapingComplete', completeListener);
    socket.on('scrapingFailed', failedListener);
  };

  const startedListener = (data: { jobId: string }) => {
    if (data.jobId === jobId) {
      onStart();
    }
  };

  const completeListener = (data: { jobId: string; result: any }) => {
    if (data.jobId === jobId) {
      isCompleted = true;
      onComplete(data.result);
    }
  };

  const failedListener = (data: { jobId: string; error: string }) => {
    if (data.jobId === jobId) {
      onError(data.error);
    }
  };

  setupListeners();

  socket.on('connect', setupListeners);
  socket.on('disconnect', () => console.log('WebSocket disconnected. Retrying...'));

  // Fallback polling
  const pollInterval = setInterval(async () => {
    if (!isCompleted) {
      try {
        const status = await pollScrapingStatus(jobId);
        if (status.state === 'completed' && !isCompleted) {
          isCompleted = true;
          onComplete(status.result);
        } else if (status.state === 'failed') {
          onError('Scraping failed');
        }
      } catch (error) {
        console.error('Error polling scraping status:', error);
      }
    } else {
      clearInterval(pollInterval);
    }
  }, 10000); // Poll every 10 seconds

  return () => {
    socket.off('scrapingStarted', startedListener);
    socket.off('scrapingComplete', completeListener);
    socket.off('scrapingFailed', failedListener);
    socket.off('connect', setupListeners);
    clearInterval(pollInterval);
  };
};

export const deleteHotel = async (id: number) => {
  const response = await api.delete(`/hotels/${id}`);
  return response.data;
};

export const pollScrapingStatus = async (jobId: string): Promise<any> => {
  try {
    const response = await api.get(`/hotels/scrape/${jobId}`);
    return response.data;
  } catch (error) {
    console.error('Error polling scraping status:', error);
    throw error;
  }
};

// New function to fetch areas
export const getAreas = async (): Promise<string[]> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    const response = await api.get<string[]>('/hotels/areas', {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};